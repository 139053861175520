import React from "react";

import SEO from "../components/seo";
import resumeLink from "../assests/Tyler Wright Resume.pdf";

function ResumePage() {

  const workExperience = [
    {
      id: 1,
      company: "Purdue Univeristy ECN",
      durationOfEmployment: "August 2020 - Present",
      location: "West Lafayette, IN",
      position: "Front End Developer",
      bullet1: "Updated frontend of Engineering Computer Network ticketing system to React.js and Material-UI that is used by over 55 staff members.",
      bullet2: "Conducted usability testing to evaluate viability of design concepts. Created and maintained frontend code and documentation standards.",
      bullet3: "Ensured design complied with current user experience and WCAG 2.o standards",
    },
    {
      id: 2,
      company: "K&D Innovation",
      durationOfEmployment: "May 2020 - Present",
      location: "Huntsville, AL",
      position: "Mobile Application Developer ",
      bullet1: "Developed a multi-platform mobile application utilizing C# and Xamarin as well as following the Model-View-ViewModel (MVVM) design pattern",
      bullet2: "Integrated Foursquare API using REST architectural standards",
      bullet3: "Converted mockup to functioning code allowing for interaction with business logic",
    },

    {
      id: 3,
      company: "Purdue University",
      durationOfEmployment: "August 2018 - December 2019",
      location: "West Lafayette, IN",
      position: "Teaching Assistant",
      bullet1: "Supported head faculty member with task such as classroom instruction material, exams, grading, and attendance",
      bullet2: "Moderated group discussions between 6 students in order to develop a safe learning environment",
      bullet3: "Educated students on proper goal setting and time management skills to help improve student’s performance in academic settings",
    },
  ]

  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`, `web developer`, `portfolio`, `frontend`, `resume`]}
        title="Resume"
      />



        <h1 className="my-3 text-2xl md:text-4xl border-b-2 border-yellow-500">Resume</h1>
        <div className="h-auto w-full m-10 p-10 rounded-md bg-gray-100 md:bg-gray-100">
          <h1 className="text-lg md:text-xl  mb-3 font-bold text-left">Languages used</h1>
          <div className="flex items-center mb-4 ">
                  <div className="bg-yellow-600 rounded-full h-4 w-4 border-gray-200 border-2 ">
                  </div>
                  <div className=" ml-2 font-semibold">HTML, CSS, JavaScript, jQuery, ReactJS, C++, C#, Xamarin, PHP, MySQL, Git, Adobe Creative Cloud</div>
                </div>
                <h1 className="text-lg md:text-xl font-bold text-left">Work Experience</h1>

          <ul className="list-none my-1 py-3">
            {workExperience.map(job => (
              <li key={job.id} className="mb-2">
                <div className="flex items-center mb-1 ">
                  <div className="bg-yellow-600 rounded-full h-4 w-4 border-gray-200 border-2 ">
                  </div>

                <div className="  font-bold">{job.company} - {job.position} </div>
                </div>
                <div className="ml-10 text-left font-medium text-blue-500">
                  {job.location} - {job.durationOfEmployment}
                  </div>
                <div className="ml-12 text-left ">
                  <ul className="list-disc mx-auto ">
                    <li>{job.bullet1}</li>
                    <li>{job.bullet2}</li>
                    <li>{job.bullet3}</li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
          <a
          className="px-4 py-2 w-2/3 text-sm font-bold text-white bg-blue-500 border-b-4 border-blue-600 rounded hover:border-yellow-600 hover:bg-yellow-500  md:w-full"
          href={resumeLink}
          download
          >
          Download a copy
          </a>

        </div>

    </>
  );
}

export default ResumePage;
